import React, { ReactElement, useEffect } from 'react';
import { Layout } from '../components/Layout/Layout';
import { Intro } from '../modules/Intro/Intro';

const SubmitReviewPage = (): ReactElement => (
    useEffect(()=>{
        if((location.pathname == '/submit-review/' || location.pathname == '/submit-review')  ) {
        
 
        const getRandomItem = (arr) => {
          const randomIndex = Math.floor(Math.random() * arr.length);
          const item = arr[randomIndex];
          return item;
        }
        let review = localStorage.getItem('review');
        if(!['https://www.trustpilot.com/review/sketchwow.com', 'https://www.g2.com/products/sketchwow/reviews', 'https://reviews.capterra.com/new/347957/26b15541-66b9-4baf-994c-da4c250ea6a3'].includes(review))
          review = null
        
        if(review === null){
          const r =  getRandomItem(['https://www.trustpilot.com/review/sketchwow.com', 'https://www.g2.com/products/sketchwow/reviews', 'https://reviews.capterra.com/new/347957/26b15541-66b9-4baf-994c-da4c250ea6a3']);
          localStorage.setItem('review',r);
          
          window.location.replace(  r )
        }else{
          if(!location.pathname.includes(review))
            window.location.replace( review )
        }
    }

        }, []),

        <Layout>
        <Intro
      title={
        <> </>
      }
      subTitle={<>Thank You</>}
      
    />
  </Layout>
);

export default SubmitReviewPage;
